import React from 'react';
import Layout from '../components/Layout/Layout';
import ESTTV from '../components/ESTTV/ESTTV';

const ESTTVPage = () => {
  return(
    <Layout>
      <ESTTV/>
    </Layout>
  )
}

export default ESTTVPage;
