import React from 'react'
import * as styles from './ESTTV.module.scss' 
import showcase1 from '../../assets/showcase1.mp4'
import showcase2 from '../../assets/showcase2.mp4'
import video from '../../assets/video.mp4'

const ESTTV = () => {
	 return(
		 <div className={styles.wrapper}>
			<div className={styles.videoContainer}>
				<video src={showcase1} type="video/mp4" className={styles.video} autoPlay muted loop/>
				<video src={video} type="video/mp4" className={styles.video} autoPlay muted loop/>		
				<video src={showcase2} type="video/mp4" className={styles.video} autoPlay muted loop/>
			</div>
			<div className={styles.videoCarousel}></div>
		 </div>
	 )
}
export default ESTTV